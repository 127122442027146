import { Stack } from 'react-bootstrap';
import starterImg from 'assets/img/spot-illustrations/2.png';
import starterDarkImg from 'assets/img/spot-illustrations/dark_2.png';
import Button from 'components/base/Button';
import { Link } from 'react-router-dom';
import logo from 'assets/img/icons/logo-smarty-full.gif';

import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import CardDeck from 'react-bootstrap'
// import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Deck } from 'components/cards/CusDeck';



const Emsdescription = () => {
  return (
    // <Stack className="flex-center content-min-h">
      <><div className='text-center'>

        

        <img style={{maxWidth: '572px', width: '100%'}} src={logo} alt={process.env.REACT_APP_COMPANY_NAME}/>
        </div>
        <div>
        <h1>Beschrijving</h1>
        <Row xs={1} md={2} className="g-4">
        <Col key={1}>
  <Card>
    <Card.Body>
      <Card.Title>Realtime Monitoring</Card.Title>
      <Card.Text>
        Dit energiebeheersysteem biedt realtime monitoringmogelijkheden, waardoor gebruikers energieverbruikspatronen kunnen volgen en direct gebieden voor optimalisatie kunnen identificeren, wat resulteert in verbeterde efficiëntie en kostenbesparingen.
      </Card.Text>
    </Card.Body>
  </Card>
</Col>
<Col key={2}>
  <Card>
    <Card.Body>
      <Card.Title>Voorspellende Analyse</Card.Title>
      <Card.Text>
        Met behulp van geavanceerde voorspellende analyses voorspelt dit energiebeheersysteem energievraag- en consumptietrends, waardoor proactieve besluitvorming en preventieve onderhoudsstrategieën mogelijk zijn, uiteindelijk het verminderen van downtime en operationele kosten.
      </Card.Text>
    </Card.Body>
  </Card>
</Col>
<Col key={3}>
  <Card>
    <Card.Body>
      <Card.Title>Integratie met IoT</Card.Title>
      <Card.Text>
        Naadloze integratie met Internet of Things (IoT)-apparaten, dit energiebeheersysteem optimaliseert energieverbruik door gegevens te verzamelen van sensoren en slimme apparaten, waardoor nauwkeurige controle en automatisering van energieverbruikende systemen mogelijk is voor maximale efficiëntie.
      </Card.Text>
    </Card.Body>
  </Card>
</Col>
<Col key={4}>
  <Card>
    <Card.Body>
      <Card.Title>Energieoptimalisatiealgoritmen</Card.Title>
      <Card.Text>
        Uitgerust met geavanceerde energieoptimalisatiealgoritmen past dit energiebeheersysteem dynamisch energiedistributie en -verbruik aan op basis van realtime data-analyse, waarbij optimale prestaties worden gegarandeerd terwijl verspilling en milieueffecten worden geminimaliseerd.
      </Card.Text>
    </Card.Body>
  </Card>
</Col>
<Col key={5}>
  <Card>
    <Card.Body>
      <Card.Title>Gebruiksvriendelijke Interface</Card.Title>
      <Card.Text>
        Met een intuïtieve gebruikersinterface biedt dit energiebeheersysteem gebruikers bruikbare inzichten en controle over energieverbruik, waardoor ze moeiteloos geïnformeerde beslissingen kunnen nemen en energiebesparende maatregelen kunnen implementeren, wat een cultuur van duurzaamheid bevordert.
      </Card.Text>
    </Card.Body>
  </Card>
</Col>


    </Row>








      </div>
      </>
    // </Stack>
    
  );

};

export default Emsdescription;
