import axios from 'axios'

const baseURL = process.env.REACT_APP_BACKEND_BASEURL;


if (localStorage.getItem('user')){
    const user = JSON.parse(localStorage.getItem('user'))
    // console.log('axios default header: '+user.token)
    // axios.defaults.headers.common['Authorization'] = user.token;
}



const api = axios.create({
    baseURL
})

export default api;