import { createStore, applyMiddleware } from "redux";
import { configureStore } from '@reduxjs/toolkit'
import {thunk} from 'redux-thunk'
import { logger } from 'redux-logger'
import rootReducer from "./rootReducer";
import { composeWithDevTools } from "@redux-devtools/extension";

// import userReducer from './user/userReducer'
// import authReducer from './slices/auth'

// const store = createStore(
//     rootReducer, composeWithDevTools(applyMiddleware(logger, thunk))
// )


let store;

if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
  const { composeWithDevTools } = require('@redux-devtools/extension');
  store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
} else {
  store = createStore(rootReducer, applyMiddleware(thunk));
}



export default store