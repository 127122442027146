import classNames from 'classnames';
import ChatWidget from 'components/common/chat-widget/ChatWidget';
import Footer from 'components/footers/Footer';
import NavbarDual from 'components/navbars/navbar-dual/NavbarDual';
import NavbarTopHorizontal from 'components/navbars/navbar-horizontal/NavbarTopHorizontal';
import NavbarTopDefault from 'components/navbars/navbar-top/NavbarTopDefault';
import NavbarVertical from 'components/navbars/navbar-vertical/NavbarVertical';
import { useAppContext } from 'providers/AppProvider';
import { useMainLayoutContext } from 'providers/MainLayoutProvider';
import { Container } from 'react-bootstrap';
import { Navigate, Outlet } from 'react-router-dom';

import { useState, useEffect } from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';


const MainLayout = () => {
  const {
    config: { navbarPosition }
  } = useAppContext();

  const { contentClass, footerClass } = useMainLayoutContext();

  const [loggedIn, setLoggedIn] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  // const [username, setusername] = useState('')
  const stateLoading = useSelector((state: RootStateOrAny) => state.auth.loading)
  const token = useSelector((state: RootStateOrAny) => state.auth.token)

  useEffect(() => {
    console.log("stateLoading: " + stateLoading)
    if (!stateLoading) {
      if (token != null) {
        console.log("MainLayout within token != loop. Token " + token)
        // If the token exists, verify it with the auth server to see if it is valid
        fetch(process.env.REACT_APP_BACKEND_BASEURL + '/users/validatetoken', {
          method: 'POST',
          headers: {
            'Authorization': token,
          },
        })
          .then((r) => r.json())
          .then((r) => {
            setLoggedIn('success' === r.message)
            setIsLoading(false)
          })
        return
      }

      if (token == null && isLoading) {
        setIsLoading(false)
        return
      }
    }

    if (isLoading) {
      console.log("if loop within useEffect mainlayout token: " + token + " loading: " + isLoading)
      return
    }
    console.log("After if loading loop")



    console.log("final comment - Loading: " + isLoading + " StateLoading: " + stateLoading + " Token: " + token)
  }, [stateLoading])



  return (

    loggedIn ? (
      <Container fluid className="px-0">
        {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
          <NavbarVertical loggedIn={loggedIn} />
        )}
        {navbarPosition === 'vertical' && <NavbarTopDefault />}
        {(navbarPosition === 'horizontal' || navbarPosition === 'combo') && (
          <NavbarTopHorizontal />
        )}
        {navbarPosition === 'dual' && <NavbarDual />}

        <div className={classNames(contentClass, 'content')}>
          <Outlet />
          <Footer className={classNames(footerClass, 'position-absolute')} />
          <ChatWidget />
        </div>
      </Container>
    ) : (isLoading ? 'Loading...' : <Navigate to="/login" />)
  );
};

export default MainLayout;
