import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faHourglassHalf } from '@fortawesome/free-regular-svg-icons';
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { fetchDashboardPages, fetchDashboardItemData } from "./redux/slices/dashboardThunk";
import {
  Icon,
  UilChartPie,
  UilCube,
  UilDocumentLayoutRight,
  UilFilesLandscapesAlt,
  UilPuzzlePiece
} from '@iconscout/react-unicons';



export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

// Define the structure of a route item
interface RouteItem {
  name: string;
  path: string;
  pathName: string;
  active: boolean;
  icon?: string;
  dropdownInside?: boolean;
  pages?: RouteItem[];
}

var arrayItemLoginStatus = {}

const user = JSON.parse(localStorage.getItem('user'));
// if (user) {
if (true) {
  arrayItemLoginStatus = {
    name: 'Logoff',
    path: '/logoff',
    pathName: 'default-dashboard',
    topNavIcon: 'user',
    icon: 'log-out',
    active: true
  }
} else {
  arrayItemLoginStatus = {
    name: 'Login',
    path: '/login',
    pathName: 'default-dashboard',
    topNavIcon: 'user',
    active: true
  }
}



var CusRouteItems = []
if (true) {
  CusRouteItems = [
  {
    label: 'dashboard',
    horizontalNavLabel: 'home',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'Home',
        icon: 'home',
        active: true,
        flat: true,
        path: '/',
        pathName: 'default-dashboard',
        // pages: [
        //   {
        //     name: 'MFT Description',
        //     path: '/mft-description',
        //     pathName: 'default-dashboard',
        //     topNavIcon: 'shopping-cart',
        //     active: true
        //   }
        // ]
      },
      // {
      //   name: 'Beschrijving',
      //   icon: 'clipboard',
      //   active: true,
      //   // flat: true,
      //   path: '/ems-description',
      //   pathName: 'default-dashboard',
      // },
      {
        name: 'Epex marktprijzen',
        active: true,
        icon: 'home',
        path: '/apps/ems/epex',
      },
      // {
      //   name: 'Dashboards',
      //   active: true,
      //   icon: 'home',
      //   path: '/apps/ems/dashboard?dashboard_page_id=1',
      // },
      // {
      //   name: 'Influx',
      //   active: true,
      //   icon: 'home',
      //   path: '/apps/ems/influx',
      // },
    ]
  },
  {
    label: 'Configuratie',
    icon: UilCube,
    pages: [
      {
        name: 'Contract & Aansluiting',
        active: true,
        icon: 'home',
        path: '/apps/ems/connection',
      },
      {
        name: 'Batterij',
        active: true,
        icon: 'home',
        path: '/apps/ems/battery',
      },


    ]
  },
  {
    label: 'Security',
    icon: UilFilesLandscapesAlt,
    pages: [
      {
        name: 'Users',
        icon: 'users',
        path: 'configuration/users',
        active: true
      },
      // {
      //   name: 'Roles',
      //   icon: 'users',
      //   path: 'configuration/roles',
      //   active: true
      // },
      //     arrayItemLoginStatus
        
      
    ]
  },

];
}


// const dispatch = useDispatch();

// dispatch(fetchDashboardPages());







export const routes: RouteItems[] = CusRouteItems