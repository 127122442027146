import SignInForm from 'components/modules/auth/SignInForm';
import AuthSimpleLayout from 'layouts/AuthSimpleLayout';

import { useState, useEffect } from 'react';

const SignIn = () => {
  const [loggedIn, setLoggedIn] = useState(false)
  const [username, setusername] = useState('')
  useEffect(() => {
    // Fetch the user username and token from local storage
    const user = JSON.parse(localStorage.getItem('user'))
    console.log("User: "+JSON.stringify(user))
    // If the token/username does not exist, mark the user as logged out
    if (!user || !user.token) {
      setLoggedIn(false)
      return
    }
  
    // If the token exists, verify it with the auth server to see if it is valid
    fetch(process.env.REACT_APP_BACKEND_BASEURL + '/users/validatetoken', {
      method: 'POST',
      headers: {
        'Authorization': user.token,
      },
    })
      .then((r) => r.json())
      .then((r) => {
        setLoggedIn('success' === r.message)
        setusername(user.username || '')
      })
      
  }, [])
  console.log("Signing page loggedIn: "+loggedIn)

  function UserLogin() {
    if (loggedIn){
      return (<span><h1>Already logged in</h1> {"\n"} <a href='link'>Log off</a></span>)
    } else {
      return <SignInForm layout="simple" loggedIn={loggedIn} />
    }
  }

  return (
    <AuthSimpleLayout>
      <UserLogin />
    </AuthSimpleLayout>
  );
};

export default SignIn;
