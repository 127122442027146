import { createSlice } from '@reduxjs/toolkit';
import { fetchDashboardPages, fetchDashboardItemData } from './dashboardThunk';

const initialState = {
    pages: [],
    loading: false,
    itemData: {},
    itemDataLoading: false
};

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDashboardPages.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchDashboardPages.fulfilled, (state, action) => {
                state.pages = action.payload;
                state.loading = false;
            })
            .addCase(fetchDashboardPages.rejected, (state) => {
                state.loading = false;
            })
            .addCase(fetchDashboardItemData.pending, (state) => {
                state.itemDataLoading = true;
            })
            .addCase(fetchDashboardItemData.fulfilled, (state, action) => {
                state.itemData = action.payload;
                state.itemDataLoading = false;
            })
            .addCase(fetchDashboardItemData.rejected, (state) => {
                state.itemDataLoading = false;
            });
    },
});

export default dashboardSlice.reducer;
