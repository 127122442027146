import { useState } from 'react';
// import Button from 'react-bootstrap/Button';
import Button from 'components/base/Button';
import Modal from 'react-bootstrap/Modal';
import { Fragment } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faWindowClose } from '@fortawesome/free-solid-svg-icons'

import PasswordChecklist from "react-password-checklist"


import { connect } from 'react-redux'
import { updateUser } from '../../redux';

const EditUser = (props) => {
  console.log("props: "+JSON.stringify(props))
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const user_id = props.user_id;
  const [password, setPassword] = useState<string>();
  const [passwordForm, setPasswordForm] = useState<string>('');
  const [passwordAgain, setPasswordAgain] = useState<string>('')
  const [home_folder, setHome_folder] = useState<number>()
  const [mail, setMail] = useState<string>()
  const [public_key, setPublic_key] = useState<string>()


  const updateUser = async (e) => {
    e.preventDefault();
    
    try {
    //   const authenticatedUser = JSON.parse(localStorage.getItem('user'))
    //   // const password = null;
      const body = { user_id, password, home_folder, mail, public_key }
    //   const response = await fetch(process.env.REACT_APP_BACKEND_BASEURL+'/users/'+props.user_id, {
    //     method: 'PUT',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'Authorization': authenticatedUser.token,
    //     },
    //     body: JSON.stringify(body)

        
    // })
    props.updateUser(body)
    console.log(body)
    } catch (error) {
    console.error(error.message)
  }
}


return (

  <>
    <Button variant="phoenix-secondary" onClick={handleShow}>
      Edit
    </Button>

    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="xl">
      <Modal.Header>
        <Modal.Title>Edit User</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Form.Group as={Row} className="mb-3" controlId="formUserID">
          <Form.Label column sm="2">
            User ID
          </Form.Label>
          <Col sm="10">
            <Form.Control type="text" placeholder="Normal text" defaultValue={props.userItem.user_id} plaintext readOnly />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formUsername">
          <Form.Label column sm="2">
            Username
          </Form.Label>
          <Col sm="10">
            <Form.Control type="text" placeholder="Normal text" defaultValue={props.userItem.username} plaintext readOnly />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formUserMail">
          <Form.Label column sm="2">
            Email
          </Form.Label>
          <Col sm="10">
            <Form.Control type="text" placeholder="Normal text" defaultValue={props.userItem.mail} onChange={ev => setMail(ev.target.value)}/>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formUserPublicKey">
          <Form.Label column sm="2">
            Public Key
          </Form.Label>
          <Col sm="10">
            <Form.Control type="text" placeholder="Normal text" defaultValue={props.userItem.public_key} onChange={ev => setPublic_key(ev.target.value)}/>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="FormUserPassword">
          <Form.Label column sm="2">
            Password
          </Form.Label>
          <Col sm="10">
            <Form.Control type="password" placeholder="Password" onChange={
              ev => {setPasswordForm(ev.target.value)
              setPassword(ev.target.value)
              }}/>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="FormUserPasswordAgain">
          <Form.Label column sm="2">
            Password again
          </Form.Label>
          <Col sm="10">
            <Form.Control type="password" placeholder="Password" onChange={ev => setPasswordAgain(ev.target.value)}/>
          </Col>
        </Form.Group>

        <PasswordChecklist
				rules={["minLength","specialChar","number","capital","match"]}
				minLength={5}
				value={passwordForm}
				valueAgain={passwordAgain}
				onChange={(isValid) => {}}
			/>

        <Form.Group as={Row} className="mb-3" controlId="FormUserHomeFolder">
          <Form.Label column sm="2">
            Home folder
          </Form.Label>
          <Col sm="10">
            <Form.Control type="text" placeholder="Normal text" defaultValue={props.userItem.home_folder} onChange={ev => setHome_folder(Number(ev.target.value))}/>
          </Col>
        </Form.Group>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="phoenix-danger" onClick={handleClose} startIcon={<FontAwesomeIcon icon={faWindowClose} />}>
          Close
        </Button>
        <Button variant="phoenix-primary" onClick={ev => updateUser(ev)} startIcon={<FontAwesomeIcon icon={faSave} />}>Save</Button>
      </Modal.Footer>
    </Modal>
  </>
);
}

// export default EditUser;




const mapStateToProps = (state, ownProps) => {
  const user_id = ownProps.user_id
  return {
    userItem: state.user.users.find(item => item.user_id === user_id)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateUser: user => dispatch(updateUser(user))
  }
}


export default connect(mapStateToProps,mapDispatchToProps)(EditUser)
